<template>
  <v-dialog
    :persistent="processing"
    max-width="600"
    v-model="modal"
  >
    <v-card>
      <v-card-title>{{ $t('resumeSubscription') }}</v-card-title>
      <v-card-subtitle>{{ $t('pleaseChooseRenewalDate') }}</v-card-subtitle>
      <v-card-text class="pt-5">
        <v-card 
          style="padding-left:20px; padding-right:20px"
        >
          <v-row>
            <v-col cols="12">
              <DateSelection
                label="Next Renewal"
                :required="true"
                v-model="subscription.nextPayment"
                :min-date="nowDate"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-row
          dense
          justify="space-between"
        >
          <v-col
            cols="13"
            md="auto"
          >
            <v-btn
              :disabled="processing"
              color="red"
              text
              @click="close"
            >
              {{ $t('abort') }}
            </v-btn>
          </v-col>

          <v-spacer />
          <v-col
            cols="12"
            md="auto"
          >
            <v-btn
              color="primary"
              :disabled="processing"
              :loading="processing"
              @click="confirm"
            >
              {{ $t('confirm') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DateSelection from '../../src/components/common/DateSelection'
export default {
    name: 'SubscriptionResumeDialog',
    components: {
      DateSelection
    },
    props: {
      subscription: {
        type: Object, 
        default: () => {}
      }
    },
    data: () => ({
      modal: true,
      processing: false,
      nowDate: new Date().toISOString().slice(0,10)
    }),
    methods: {
        close() {
          this.modal = false
          this.$emit('closed')
        },
        confirm() {
        this.processing = true
        this.$store.dispatch('resumeSubscription', this.subscription).then(result =>{
          this.processing = false
          this.close()
          if(result) {
            this.$store.commit('updateActionSuccess', {
              message: this.$t('success'), 
              subMessage: this.$t('subscriptionHasBeenResumed')
            })
          } else {
              this.$store.commit('updateActionError', {
                message: this.$t('unableToResumeSubscription')
            })
          }
        }).catch(error => {
            this.processing = false
            this.close()
            this.$store.commit('updateActionError', {
                message: this.$t('unableToResumeSubscription')
            })
          })
        }
    }
}
</script>